import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Services from "../assets/img/services-head.png"
import digital from "../assets/img/digital-service.png"
import checkModal from "../assets/img/check-modal.png";

class Servicess extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');

        document.querySelector('#site').innerHTML = this.state.value;
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|',1).toString();  
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
          method: 'POST',
          body: JSON.stringify({
            "text_327649": title,
            "text_204202": document.querySelector('#site').value,
            "text_775908": document.querySelector('#name').value,
            "text_532192": document.querySelector('#email').value,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8"
          }
        });
        response.then(res => {
          if (res.status === 200) {
            document.querySelector('.status').innerHTML = "Success!";
          }
          else {
            document.querySelector('.status').innerHTML = "Bir hata oluştu." 
          }
        });
      }
    render() {
        return (
            <Layout>
                <SEO title="Services" description="With the strategy tailored specifically for your brand, we help you to achieve your digital goals and to carry your brand to higher levels." />
                <div className="servicesPage container">
                    <div className="servicesPage-head">
                        <img alt="alt text" src={Services} />
                        <h1>Dijital Marketing <br /> Services</h1>
                        <span className="red-head">SERVICES</span>
                        <p className="servicesPage-head_desc">We develop all-encompassing strategies that are suitable for your brand. With the strategy tailored specifically for your brand, we help you to achieve your digital goals and to carry your brand to higher levels.</p>
                    </div>
                    <div className="servicesPage-col">
                        <div className="row">
                            <div className="servicesPage-col_box">
                                <svg id="Search" xmlns="http://www.w3.org/2000/svg" width="75.793" height="75.793" viewBox="0 0 151.793 151.793">
                                    <path id="Path_376" data-name="Path 376" d="M0,0H151.793V151.793H0Z" fill="none" fillRule="evenodd" />
                                    <path id="Path_377" data-name="Path 377" d="M60.186,73.3a6.325,6.325,0,0,1,8.944-8.944l25.3,25.3A6.325,6.325,0,0,1,85.485,98.6l-25.3-25.3Z" transform="translate(30.212 32.37)" fill="#111" opacity="0.3" />
                                    <path id="Path_378" data-name="Path 378" d="M60.94,92.563A31.623,31.623,0,1,0,29.316,60.94,31.624,31.624,0,0,0,60.94,92.563Zm0,12.649A44.273,44.273,0,1,1,105.212,60.94,44.273,44.273,0,0,1,60.94,105.212Z" transform="translate(8.632 8.632)" fill="#111" />
                                </svg>
                                <h2>Analysis</h2>
                                <ul>
                                    <li><Link to="/analysis/#pazarlama-audit">Marketing & Brand Audit</Link></li>
                                    <li><Link to="/analysis/#website-audit"> Website UX Audit</Link></li>
                                    <li><Link to="/analysis/#seo-audit">SEO & Content Audit</Link></li>
                                </ul>
                                <Link to="/analysis/" className="content-link">Check out the analysis process.</Link>
                            </div>

                            <div className="servicesPage-col_box">
                                <svg id="Group" xmlns="http://www.w3.org/2000/svg" width="75.62" height="75.62" viewBox="0 0 124.62 124.62">
                                    <path id="Path_379" data-name="Path 379" d="M0,0H124.62V124.62H0Z" fill="none" fillRule="evenodd" />
                                    <path id="Path_380" data-name="Path 380" d="M88.336,69.618A15.578,15.578,0,1,1,103.914,54.04,15.578,15.578,0,0,1,88.336,69.618ZM41.6,54.04a20.77,20.77,0,1,1,20.77-20.77A20.77,20.77,0,0,1,41.6,54.04Z" transform="translate(5.129 3.078)" fill="#111" opacity="0.3" />
                                    <path id="Path_381" data-name="Path 381" d="M91.394,64.555c17.688.193,32.131,9.136,33.218,28.036a2.646,2.646,0,0,1-2.817,3.116H101.773A51.7,51.7,0,0,0,91.394,64.555ZM0,91.549C2.016,66.766,22.13,54.167,46.646,54.167c24.861,0,45.287,11.907,46.808,37.386.061,1.015,0,4.154-3.9,4.154H3.778c-1.3,0-3.884-2.808-3.774-4.158Z" transform="translate(0 13.336)" fill="#111" />
                                </svg>

                                <h2>Acquisition</h2>
                                <ul>
                                    <li><Link to="/digital-ads/">Google Ads</Link></li>
                                    <li><Link to="/acquisition/#facebook-reklamlari">Facebook Ads</Link></li>
                                    <li><Link to="/acquisition/#linkedin-reklamlari">LinkedIn Ads</Link></li>
                                    <li><Link to="/search-engine-optimization/">SEO / SEM</Link></li>
                                    <li><Link to="/email-marketing/">Email Marketing</Link></li>
                                </ul>
                                <Link to="/acquisition/" className="content-link">Check out the traffic acquisition process.</Link>
                            </div>
                        </div>

                        <div className="row">
                            <div className="servicesPage-col_box">
                                <svg id="Bag_1" data-name="Bag 1" xmlns="http://www.w3.org/2000/svg" width="75.62" height="75.62" viewBox="0 0 124.62 124.62">
                                    <path id="Path_382" data-name="Path 382" d="M0,0H124.62V124.62H0Z" fill="none" fillRule="evenodd" />
                                    <path id="Path_383" data-name="Path 383" d="M64.488,42.629V37.437a10.385,10.385,0,1,0-20.77,0v5.193H33.333V37.437a20.77,20.77,0,1,1,41.54,0v5.193Zm0,0V37.437a10.385,10.385,0,1,0-20.77,0v5.193H33.333V37.437a20.77,20.77,0,1,1,41.54,0v5.193Z" transform="translate(8.207 4.103)" fill="#111" opacity="0.3" />
                                    <path id="Path_384" data-name="Path 384" d="M31.08,37.5H84.593a5.193,5.193,0,0,1,5.122,4.339l7.646,45.879A10.385,10.385,0,0,1,87.117,99.81H28.555A10.385,10.385,0,0,1,18.311,87.718l7.646-45.879A5.193,5.193,0,0,1,31.08,37.5Z" transform="translate(4.474 9.233)" fill="#111" fillRule="evenodd" />
                                </svg>

                                <h2>Conversion</h2>
                                <ul>
                                    <li><Link to="/conver/#cro-optimizasyonu">CR Optimization</Link></li>
                                    <li><Link to="/web-development/">Website Enhancements</Link></li>
                                    <li><Link to="/ui-ux-design/">UI/UX Design</Link></li>
                                    <li><Link to="/conver/#lead-generation">Lead Generation</Link></li>
                                </ul>
                                <Link to="/conver/" className="content-link">Check out the conversion process.</Link>
                            </div>

                            <div className="servicesPage-col_box">

                                <img alt="alt text" src={digital} />
                                <p>In order to strengthen your presence in the digital world, we first determine your needs, and then we produce result-oriented solutions.</p>
                                <button className="red-button" onClick={(e) => this.showModal()}>Free Performance Report</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="check-modal">
          <span onClick={(e) => this.closeModal()}>X</span>
          <h2 className="form-head">Fill the form</h2>             <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
          <div className="check-modal_container">
            <form onSubmit={this.handleSubmit} >
              <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required  placeholder="Enter your website." />
              <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
              <input type="email" id="email" required  name="text_532192" placeholder="Enter yor email" />
              <button>SEND</button>
              <div className="status"></div>
            </form>
            <img alt="alt text" src={checkModal} />
          </div>
        </div>
            </Layout>
        )
    }
}
export default Servicess
